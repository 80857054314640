/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { Link } from '@quintype/components';
import { string } from 'prop-types';

import { OPEN_HAMBURGER_MENU, OPEN_SEARCHBAR } from '../../../store/actions';
import { MenuItem } from '../../menu-item';
import HamburgerMenu from '../../../atoms/hamburger-menu';
// import MessageWrapper from '../../../molecules/forms/message-wrapper';
// import { getOauthAuthorizeUrl, getAutoSSOUrl } from '@quintype/bridgekeeper-js';
// import SvgIconHandler from '../../../atoms/svg-icon-handler';
// import SvgIcon from '../../../atoms/svg-icon-handler';

import { NavbarSearch } from '../navbar-search';
import MobileSearch from '../navbar-search/mobile-search';
import SideBarMenu from '../../../molecules/sidebar-menu';
import DfpAd from '../../../atoms/dfp-ad';
import MobileBottomSticky from '../../../molecules/mobile-bottom-sticky';
import GlobalHeader from '../global-header';
import { isMobile } from 'react-device-detect';
import './navbar.m.css';

const NavBar = ({ pageType, state, currentPath }) => {
  // Import account modal dynamically
  const [page, setPage] = useState('');
  // const AccountModal = lazy(() => import('../../../login/AccountModal'));
  // const [showAccountModal, setShowAccountModal] = useState(false);
  // const [message, setMessage] = useState(null);
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const [showUserHandler, setUserHandler] = useState(false);
  const getState = useSelector((state) => state);
  // const publisherAttributes = get(getState, ['qt', 'config', 'publisher-attributes'], {});
  // const enableLogin = get(publisherAttributes, ['enableLogin'], true);
  const isHamburgerMenuOpen = get(getState, ['isHamburgerMenuOpen'], false);
  const menu = get(getState, ['qt', 'data', 'navigationMenu', 'default'], []);
  // const hamburgerMenu = get(getState, ['qt', 'data', 'navigationMenu', 'default'], []);
  // const displayStyle = isHamburgerMenuOpen ? 'flex' : 'none';
  // const domainSlug = get(getState, ['qt', 'config', 'domainSlug'], '');
  // const clientId = get(publisherAttributes, ['sso_login', 'client_id'], '');
  // const redirectUrl = domainSlug
  //   ? get(publisherAttributes, ['sso_login', 'subdomain', domainSlug, 'redirect_Url'], '')
  //   : get(publisherAttributes, ['sso_login', 'redirect_Url'], '');

  // const ssoLoginIsEnable = get(publisherAttributes, ['sso_login', 'is_enable'], false);
  // const isAutoSSOEnabled = get(publisherAttributes, ['auto_sso', 'is_enable'], false);

  const toggleHandler = () => {
    dispatch({
      type: OPEN_HAMBURGER_MENU,
      isHamburgerMenuOpen: !isHamburgerMenuOpen
    });
    // dispatch({
    //   type: OPEN_SEARCHBAR,
    //   isSearchBarOpen: false
    // });
  };

  // const userAccountHandler = () => {
  //   setUserHandler(!showUserHandler);
  //   dispatch({
  //     type: OPEN_SEARCHBAR,
  //     isSearchBarOpen: false
  //   });
  // };

  // const getCurrentUser = async () => {
  //   // Import current user function only when this function is called
  //   const { currentUser } = await import('@quintype/bridgekeeper-js');
  //   try {
  //     const currentUserResp = await currentUser();
  //     dispatch({ type: MEMBER_UPDATED, member: get(currentUserResp, ['user'], null) });
  //     return currentUserResp;
  //   } catch (err) {
  //     console.log('error--------', err);
  //   }
  // };

  // const logoutHandler = async () => {
  //   // Import logout on click of the logout button
  //   const { logout } = await import('@quintype/bridgekeeper-js');
  //   logout()
  //     .then(() => {
  //       dispatch({
  //         type: MEMBER_UPDATED,
  //         member: null
  //       });
  //     })
  //     .finally(() => {
  //       setShowAccountModal(false);
  //       setUserHandler(false);
  //     });
  // };

  // const userBtnClick = () => {
  //   setShowAccountModal(true);
  //   dispatch({
  //     type: OPEN_SEARCHBAR,
  //     isSearchBarOpen: false
  //   });
  // }

  const fixGutterAds = () => {
    const lhs = document.getElementById('gutter-lhs');
    const rhs = document.getElementById('gutter-rhs');
    if (lhs && rhs) {
      const offset = lhs.offsetTop;
      if (window.pageYOffset >= offset) {
        lhs.classList.add('sticky');
        rhs.classList.add('sticky');
      } else {
        lhs.classList.remove('sticky');
        rhs.classList.remove('sticky');
      }
    }
  };

  const getNavbarMenu = (menu) => {
    return (
      <ul styleName="navbar" id="main-navbar">
        <img
          src={`/icons/home_icon.svg`}
          alt="BQP Hindi"
          height="18px"
          width="18px"
          onClick={() => (document.location.href = '/')}
          styleName="dropdown"
        />
        {pageType !== 'home-page' && page !== '/the-latest-news' ? (
          <li styleName="dropdown">
            <Link href="/the-latest-news?src=topnav">लेटेस्ट</Link>
          </li>
        ) : null}
        {menu.length > 0 &&
          menu.map((item) => {
            if (item.title === 'बजट 2024') {
              return (
                <img
                  key="budget-icon" // Adding a unique key prop here
                  src={`/icons/budget_menu_icon.png`}
                  alt="NDTV Profit हिंदी"
                  height="18px"
                  onClick={() => (document.location.href = `${item.completeUrl}?src=topnav`)}
                  styleName="dropdown"
                />
              );
            }
            return (
              <li key={item.title} styleName="dropdown">
                <MenuItem
                  item={item}
                  toggleHandler={() =>
                    dispatch({
                      type: OPEN_SEARCHBAR,
                      isSearchBarOpen: false
                    })
                  }
                />
              </li>
            );
          })}
      </ul>
    );
  };

  const cleanCurrentPath = currentPath.split('?')[0];
  const formattedCategory = pageType === 'home-page'
    ? 'homepage'
    : cleanCurrentPath.substring(1).split('/')[0].replace(/[-\s]/g, '');

  useEffect(() => {
    if (pageType && pageType !== 'story-page' && window.dataLayer) {
      window.dataLayer.push({
        event: 'page_property',
        page_template: pageType,
        category: formattedCategory,
      });
    }
  }, [cleanCurrentPath]);


  const gutterAdsLhs = () => {
    return (
      <div className="responsive-ad-container align-left desktop-only" id="gutter-lhs">
        <DfpAd adtype={'GutterAdsLHS'} />
      </div>
    );
  };

  const gutterAdsRhs = () => {
    return (
      <div className="responsive-ad-container align-right desktop-only" id="gutter-rhs">
        <DfpAd adtype={'GutterAdsRHS'} />
      </div>
    );
  };

  return (
    <>
      <GlobalHeader features={state.qt.config.features} />
      <div styleName="main-wrapper" id="sticky-navbar">
        <nav styleName="wrapper" className="container">
          <HamburgerMenu
            onMenuToggle={() => toggleHandler()}
            isMegaMenuOpen={isHamburgerMenuOpen}
          />
          {getNavbarMenu(menu)}
          <div styleName="white-shadow" className="mobile-only"></div>
          <div styleName="ham-with-search" id="main-navbar">
            <NavbarSearch />
            <MobileSearch />
            <SideBarMenu menu={menu} />
          </div>
        </nav>
      </div>

      {!isHamburgerMenuOpen && !isMobile && (
        <div className="responsive-ad-container align-left desktop-only" id="gutter-lhs">
          <DfpAd
            adtype={'GutterAds1LHS'}
            adUnit="LHS_120x600"
            sizes={[[120, 600]]}
          />
        </div>
      )}
      {!isHamburgerMenuOpen && !isMobile && (
        <div className="responsive-ad-container align-right desktop-only" id="gutter-rhs">
          <DfpAd
            adtype={'GutterAds1RHS'}
            adUnit="RHS_120x600"
            sizes={[[120, 600]]}
          />
        </div>
      )}

      <div className="height-zero">
        <DfpAd
          adtype="PopUpAcrossAllSectionsAndPages"
          adUnit="Interstitial_1x1"
          sizes={[[1, 1]]}
        />
      </div>
      {!isHamburgerMenuOpen ? <MobileBottomSticky /> : null}
    </>
  );
};
NavBar.propTypes = {
  pageType: string
};
const mapStateToProps = (state) => {
  return {
    currentPath: get(state, ['qt', 'currentPath'], ''),
    pageType: get(state, ['qt', 'pageType'], ''),
    state
  };
};

export default connect(mapStateToProps, () => ({}))(NavBar);
